import { checkEndpointRights } from '@palette/helpers/ApiHelper';
import { formatToISOStringWithFractionalSeconds } from '@palette/helpers/MomentHelper';

import { MIXED_CURRENCY_VALUE } from '@palette/constants/currency';
import { RIGHTS } from '@palette/constants/profile';

import apiConfig from '@palette/config/api';

import { ApiService, getConfig } from '@palette/services/ApiService';

export function* statementV3getListPeriods(payload) {
  const { from, to, currency = '', isIC = false, userId = null } = payload;

  const data = {
    from: formatToISOStringWithFractionalSeconds(from),
    to: formatToISOStringWithFractionalSeconds(to, true),
  };

  let endpoint = apiConfig.ENDPOINTS.statementV3.listPeriods.replace('{from}', data.from).replace('{to}', data.to).replace('{currency}', currency);

  if (userId) {
    endpoint += `&payee=${userId}`;
  }

  if (isIC) {
    endpoint = apiConfig.ENDPOINTS.ic.statementV3.listPeriods.replace('{from}', data.from).replace('{to}', data.to);
  }

  return yield checkEndpointRights(
    () => ApiService.get(endpoint, undefined, getConfig()),
    [isIC ? RIGHTS.IC.COMPENSATION : RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* statementV3getPeriod(payload) {
  const { period, currency } = payload;

  const endpoint = apiConfig.ENDPOINTS.statementV3.detailsPeriod.replace('{period}', period).replace('{currency}', currency ?? MIXED_CURRENCY_VALUE);

  return yield checkEndpointRights(
    () => ApiService.get(endpoint, undefined, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* statementV3getOneStatement(payload) {
  const { statementPeriodId, currency = '', isIC = false } = payload;

  let endpoint = apiConfig.ENDPOINTS.statementV3.oneStatement.replace('{statementId}', statementPeriodId).replace('{currency}', currency);

  if (isIC) {
    endpoint = apiConfig.ENDPOINTS.ic.statementV3.oneStatement.replace('{statementId}', statementPeriodId);
  }

  return yield checkEndpointRights(
    () => ApiService.get(endpoint, undefined, getConfig()),
    [isIC ? RIGHTS.IC.COMPENSATION : RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* statementV3addAction(payload) {
  const { statementPeriodId, type } = payload;

  const params = { type };

  const endpoint = apiConfig.ENDPOINTS.statementV3.addAction.replace('{statementId}', statementPeriodId);

  return yield checkEndpointRights(
    () => ApiService.post(endpoint, params, getConfig()),
    [RIGHTS.ADMIN.STATEMENTS.VALIDATIONS.MANAGE],
  );
}

export function* statementV3addMultipleActions(payload) {
  const { statementPeriodIds, type } = payload;

  const params = { type, statementIds: statementPeriodIds };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.statementV3.addMultipleActions, params, getConfig()),
    [RIGHTS.ADMIN.STATEMENTS.VALIDATIONS.MANAGE],
  );
}

export function* statementV3deleteAction(payload) {
  const { statementPeriodId, userId, type } = payload;

  const params = { authorId: userId, type };

  const endpoint = apiConfig.ENDPOINTS.statementV3.deleteAction.replace('{statementId}', statementPeriodId);

  return yield checkEndpointRights(
    () => ApiService.post(endpoint, params, getConfig()),
    [RIGHTS.ADMIN.STATEMENTS.VALIDATIONS.MANAGE],
  );
}

export function* statementV3getListCorrections(payload) {
  const { statementId, currency = '', isIC = false } = payload;

  let endpoint = apiConfig.ENDPOINTS.statementV3.listCorrections.replace('{statementId}', statementId).replace('{currency}', currency);

  if (isIC) {
    endpoint = apiConfig.ENDPOINTS.ic.statementV3.listCorrections.replace('{statementId}', statementId);
  }

  return yield checkEndpointRights(
    () => ApiService.get(endpoint, undefined, getConfig()),
    [isIC ? RIGHTS.IC.COMPENSATION : RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* statementV3correctionSuggestedValidation(payload) {
  const { statementId, correctionId, status } = payload;

  const params = { status };

  const endpoint = apiConfig.ENDPOINTS.statementV3.correctionSuggestedValidation.replace('{statementId}', statementId).replace('{correctionId}', correctionId);

  return yield checkEndpointRights(
    () => ApiService.put(endpoint, params, getConfig()),
    [RIGHTS.ADMIN.STATEMENTS.VALIDATIONS.MANAGE],
  );
}

export function* statementV3correctionDelete(payload) {
  const { statementId, correctionId } = payload;

  const endpoint = apiConfig.ENDPOINTS.statementV3.deleteCorrection.replace('{statementId}', statementId).replace('{correctionId}', correctionId);

  return yield checkEndpointRights(
    () => ApiService.delete(endpoint, undefined, getConfig()),
    [RIGHTS.ADMIN.STATEMENTS.VALIDATIONS.MANAGE],
  );
}

export function* statementV3correctionCreate(payload) {
  const { statementId, amount, currency, reason = '' } = payload;

  const params = { amount, currency, reason };

  const endpoint = apiConfig.ENDPOINTS.statementV3.createCorrection.replace('{statementId}', statementId);

  return yield checkEndpointRights(
    () => ApiService.post(endpoint, params, getConfig()),
    [RIGHTS.ADMIN.STATEMENTS.VALIDATIONS.MANAGE],
  );
}

export function* statementV3correctionUpdate(payload) {
  const { statementId, correctionId, amount, currency, reason = '' } = payload;

  const params = { amount, currency, reason };

  const endpoint = apiConfig.ENDPOINTS.statementV3.updateCorrection.replace('{statementId}', statementId).replace('{correctionId}', correctionId);

  return yield checkEndpointRights(
    () => ApiService.put(endpoint, params, getConfig()),
    [RIGHTS.ADMIN.STATEMENTS.VALIDATIONS.MANAGE],
  );
}

export function* statementV3getListCommissions(payload) {
  const { statementId, currency } = payload;

  const endpoint = apiConfig.ENDPOINTS.statementV3.listCommissions.replace('{statementId}', statementId).replace('{currency}', currency);

  return yield checkEndpointRights(
    () => ApiService.get(endpoint, undefined, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* statementV3getICListCommissions(payload) {
  const { statementId } = payload;

  const endpoint = apiConfig.ENDPOINTS.ic.statementV3.listCommissions.replace('{statementId}', statementId);

  return yield checkEndpointRights(
    () => ApiService.get(endpoint, undefined, getConfig()),
    [RIGHTS.IC.COMPENSATION],
  );
}
