/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from '@reduxjs/toolkit';
import { original } from 'immer';
import _cloneDeep from 'lodash/cloneDeep';

import { STATEMENT_ACTION_TYPES } from '@palette/constants/StatementV3';

/*
 * Initial State
 */
const initialState = {
  listPeriods: [],
  listPeriodsIsPending: false,
  periodDetails: null,
  periodDetailsIsPending: false,
  oneStatement: null,
  oneStatementIsPending: false,
  addActionIsPending: false,
  addMultipleActionsIsPending: false,
  deleteActionIsPending: false,
  ongoingBulkApproveStatus: null,
  ongoingBulkMarkAsPaidStatus: null,
  listCorrections: [],
  listCorrectionsIsPending: false,
  deleteCorrectionsIsPending: false,
  addEditAdjustmentIsPending: false,
  listCommissions: [],
  listCommissionsIsPending: false,
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'statementV3',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* getListPeriods */
    getListPeriods: (state) => {
      state.listPeriodsIsPending = true;
    },
    setListPeriods: (state, { payload }) => {
      const { listPeriods } = payload;
      state.listPeriods = listPeriods;
    },
    getListPeriodsCompleted: (state) => {
      state.listPeriodsIsPending = false;
    },
    /* getPeriodDetails */
    getPeriodDetails: (state) => {
      state.periodDetailsIsPending = true;
    },
    setPeriodDetails: (state, { payload }) => {
      const { periodDetails } = payload;
      state.periodDetails = periodDetails;
    },
    getPeriodDetailsCompleted: (state) => {
      state.periodDetailsIsPending = false;
    },
    /* getOneStatement */
    getOneStatement: (state) => {
      state.oneStatementIsPending = true;
    },
    setOneStatement: (state, { payload }) => {
      const { oneStatement } = payload;
      state.oneStatement = oneStatement;
    },
    getOneStatementCompleted: (state) => {
      state.oneStatementIsPending = false;
    },
    /* addAction */
    addAction: (state) => {
      state.addActionIsPending = true;
    },
    addActionCompleted: (state) => {
      state.addActionIsPending = false;
    },
    /* addMultipleActions */
    addMultipleActions: (state, { payload }) => {
      state.addMultipleActionsIsPending = true;
      const {
        statementPeriodIds,
        type,
        currency,
        from,
        to,
        isForIC = false,
      } = payload;

      if (type === STATEMENT_ACTION_TYPES.APPROVAL) {
        state.ongoingBulkApproveStatus = {
          index: 0,
          statementPeriodIds,
          type,
          currency,
          from,
          to,
          isForIC,
        };
      } else if (type === STATEMENT_ACTION_TYPES.PAYMENT) {
        state.ongoingBulkMarkAsPaidStatus = {
          index: 0,
          statementPeriodIds,
          type,
          currency,
          from,
          to,
        };
      }
    },
    updateOngoingBulkStatusIndex: (state, { payload }) => {
      const { type } = payload;

      if (type === STATEMENT_ACTION_TYPES.APPROVAL) {
        const clonedOngoingBulkApproveStatus = _cloneDeep(original(state.ongoingBulkApproveStatus));

        clonedOngoingBulkApproveStatus.index += 1;
        state.ongoingBulkApproveStatus = clonedOngoingBulkApproveStatus;
      } else if (type === STATEMENT_ACTION_TYPES.PAYMENT) {
        const clonedOngoingBulkMarkAsPaidStatus = _cloneDeep(original(state.ongoingBulkMarkAsPaidStatus));

        clonedOngoingBulkMarkAsPaidStatus.index += 1;
        state.ongoingBulkMarkAsPaidStatus = clonedOngoingBulkMarkAsPaidStatus;
      }
    },
    addMultipleActionsCompleted: (state) => {
      state.addMultipleActionsIsPending = false;
      state.ongoingBulkApproveStatus = null;
      state.ongoingBulkMarkAsPaidStatus = null;
    },
    /* deleteAction */
    deleteAction: (state) => {
      state.deleteActionIsPending = true;
    },
    deleteActionCompleted: (state) => {
      state.deleteActionIsPending = false;
    },
    /* getListCorrections */
    getListCorrections: (state) => {
      state.listCorrectionsIsPending = true;
    },
    setListCorrections: (state, { payload }) => {
      const { listCorrections } = payload;
      state.listCorrections = listCorrections;
    },
    getListCorrectionsCompleted: (state) => {
      state.listCorrectionsIsPending = false;
    },
    /* Correction suggested validation */
    correctionSuggestedValidation: () => {
      // Nothing to do here
    },
    correctionSuggestedValidationCompleted: () => {
      // Nothing to do here
    },
    /* Correction add or edit */
    addEditAdjustment: (state) => {
      state.addEditAdjustmentIsPending = true;
    },
    addEditAdjustmentCompleted: (state) => {
      state.addEditAdjustmentIsPending = false;
    },
    /* Correction delete */
    correctionDelete: (state) => {
      state.deleteCorrectionsIsPending = true;
    },
    correctionDeleteCompleted: (state) => {
      state.deleteCorrectionsIsPending = false;
    },
    /* getListCommissions */
    getListCommissions: (state) => {
      state.listCommissionsIsPending = true;
    },
    setListCommissions: (state, { payload }) => {
      const { listCommissions } = payload;
      state.listCommissions = listCommissions;
    },
    getListCommissionsCompleted: (state) => {
      state.listCommissionsIsPending = false;
    },
    /* getICListCommissions */
    getICListCommissions: (state) => {
      state.listCommissionsIsPending = true;
    },
    setICListCommissions: (state, { payload }) => {
      const { listCommissions } = payload;
      state.listCommissions = listCommissions;
    },
    getICListCommissionsCompleted: (state) => {
      state.listCommissionsIsPending = false;
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];
const getProps = (_, props) => props;
const getListPeriods = (state) => root(state).listPeriods;
const getListPeriodsIsPending = (state) => root(state).listPeriodsIsPending;
const getPeriodDetails = (state) => root(state).periodDetails;
const getPeriodDetailsIsPending = (state) => root(state).periodDetailsIsPending;
const getOneStatement = (state) => root(state).oneStatement;
const getOneStatementIsPending = (state) => root(state).oneStatementIsPending;
const addActionIsPending = (state) => root(state).addActionIsPending;
const addMultipleActionsIsPending = (state) => root(state).addMultipleActionsIsPending;
const deleteActionIsPending = (state) => root(state).deleteActionIsPending;
const getOngoingBulkApproveStatus = (state) => root(state).ongoingBulkApproveStatus;
const getOngoingBulkMarkAsPaidStatus = (state) => root(state).ongoingBulkMarkAsPaidStatus;
const getListCorrections = (state) => root(state).listCorrections;
const getListCorrectionsIsPending = (state) => root(state).listCorrectionsIsPending;
const deleteCorrectionsIsPending = (state) => root(state).deleteCorrectionsIsPending;
const addEditAdjustmentIsPending = (state) => root(state).addEditAdjustmentIsPending;
const getListCommissions = (state) => root(state).listCommissions;
const getListCommissionsIsPending = (state) => root(state).listCommissionsIsPending;

const getApprovalIsPending = createSelector(
  [addMultipleActionsIsPending, getOngoingBulkApproveStatus, getProps],
  (bulkApproveIsPending, ongoingBulkApproveStatus, { statementPeriodId }) => {
    if (!bulkApproveIsPending || ongoingBulkApproveStatus === null || !ongoingBulkApproveStatus.statementPeriodIds.includes(statementPeriodId)) return false;
    return ongoingBulkApproveStatus.statementPeriodIds.indexOf(statementPeriodId) === ongoingBulkApproveStatus.index;
  },
);

const getApprovalIsWaiting = createSelector(
  [addMultipleActionsIsPending, getOngoingBulkApproveStatus, getProps],
  (bulkApproveIsPending, ongoingBulkApproveStatus, { statementPeriodId }) => {
    if (!bulkApproveIsPending || ongoingBulkApproveStatus === null || !ongoingBulkApproveStatus.statementPeriodIds.includes(statementPeriodId)) return false;
    return ongoingBulkApproveStatus.statementPeriodIds.indexOf(statementPeriodId) > ongoingBulkApproveStatus.index;
  },
);

const getMarkAsPaidIsPending = createSelector(
  [addMultipleActionsIsPending, getOngoingBulkMarkAsPaidStatus, getProps],
  (bulkMarkAsPaidIsPending, ongoingBulkMarkAsPaidStatus, { statementPeriodId }) => {
    if (!bulkMarkAsPaidIsPending || ongoingBulkMarkAsPaidStatus === null || !ongoingBulkMarkAsPaidStatus.statementPeriodIds.includes(statementPeriodId)) return false;
    return ongoingBulkMarkAsPaidStatus.statementPeriodIds.indexOf(statementPeriodId) === ongoingBulkMarkAsPaidStatus.index;
  },
);

const getMarkAsPaidIsWaiting = createSelector(
  [addMultipleActionsIsPending, getOngoingBulkMarkAsPaidStatus, getProps],
  (bulkMarkAsPaidIsPending, ongoingBulkMarkAsPaidStatus, { statementPeriodId }) => {
    if (!bulkMarkAsPaidIsPending || ongoingBulkMarkAsPaidStatus === null || !ongoingBulkMarkAsPaidStatus.statementPeriodIds.includes(statementPeriodId)) return false;
    return ongoingBulkMarkAsPaidStatus.statementPeriodIds.indexOf(statementPeriodId) > ongoingBulkMarkAsPaidStatus.index;
  },
);

export const selectors = {
  getListPeriods,
  getListPeriodsIsPending,
  getPeriodDetails,
  getPeriodDetailsIsPending,
  getOneStatement,
  getOneStatementIsPending,
  addActionIsPending,
  addMultipleActionsIsPending,
  deleteActionIsPending,
  getApprovalIsPending,
  getApprovalIsWaiting,
  getOngoingBulkApproveStatus,
  getOngoingBulkMarkAsPaidStatus,
  getMarkAsPaidIsPending,
  getMarkAsPaidIsWaiting,
  getListCorrections,
  getListCorrectionsIsPending,
  deleteCorrectionsIsPending,
  addEditAdjustmentIsPending,
  getListCommissions,
  getListCommissionsIsPending,
};
